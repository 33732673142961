/*! {!project_name!} v0.2.0 */
/**
 * JS for: {!project_name!}
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 *
 * Modules
 * jQuery document ready
 * 
 * @todo Add each module/section needed
 * 
 */
/* Modules */
const $ = require('jquery');
const Swiper = require('swiper');
var scrollMonitor = require("scrollmonitor");

/**
 * Scroll to
 * Page scroll to DOM selector
 * 
 * @param string str_selector The object id/class.
 * @param string|integer int_duration A string or number determining how long the animation will run. 
 */
function scroll_to(selector, offset, duration)
{
	scroll_position = $('' + selector + '').offset().top + offset;
	$('html, body').animate({scrollTop: scroll_position }, duration);
}

function toggle_content()
{
	$('.team-bio h5 + ul').hide();
	var template = $('#toogle-link-template').html();
	$('.team-bio h5').append(template);
	$('.toogle-link').html('[ ' + $('.toogle-link').data('label-show') + ' ]').on('click', function()
		{
			if ( $(this).parent().next('ul').is(':visible') ) {
				$(this).parent().next('ul').slideUp();
				$(this).html('[ ' + $(this).data('label-show') + ' ]');
			} else {
				$(this).parent().next('ul').slideDown();
				$(this).html('[ ' + $(this).data('label-hide') + ' ]');
			}
		});
}

/**
 * Toggle display result message
 */
function notification_display()
{
	var auto_hide;
	var result_message_wrapper = $('.notification');
	if (0 < result_message_wrapper.length && '' != result_message_wrapper.html()) {
		result_message_wrapper.click(function(e) 
		{
			//result_message.slideUp('fast');
			result_message_wrapper.fadeOut('fast');
			clearTimeout(auto_hide);
			e.preventDefault();
			e.stopPropagation();
		});
		if (result_message_wrapper.is(':visible')) {
			result_message_wrapper.fadeTo('fast', 0.5).fadeTo('slow', 1.0);
		} else {
			//result_message.slideDown('slow');
			result_message_wrapper.fadeIn('slow');
			auto_hide = setTimeout(function() 
			{
				//result_message.slideUp('slow');
				result_message_wrapper.fadeOut('fast');
			}, 6000);
		}
	}
}
function grecaptcha_callback(token)
{
	$('#form-contact').submit();
}
window.grecaptcha_callback = grecaptcha_callback;
/* jQuery document ready */
$(document).ready(function()
{

	/* The swiper stuff */
	if (0 < $('.swiper-container.home').length) {
		var slider_loop = $('.swiper-container.home .swiper-slide ').length - 1;
		var swiperHome = new Swiper ('.swiper-container.home', {
		    direction: 'horizontal',
		    slidesPerview: 1,
		    loop: slider_loop,
		    watchOverflow: true,
		    touchEventsTarget: 'wrapper',
		    grabCursor: true, 
		    autoplay: {
		    	delay: 16000,
		  	},
		  	autoplay: false,
		    scrollbar: {
		    	hide: true,
		    },
		    navigation: {
			    nextEl: '.swiper-button-next',
			    prevEl: '.swiper-button-prev',
				},
		    pagination: {
		    	el: '.swiper-pagination',
		    	type: 'bullets',
		    	clickable: true,
		  	},
		  	keyboard: {
		    	enabled: true,
		    }
		});
	}

	/* The scroll to links */
	$('.scroll-to').click(function(e)
	{
		if (0 < $('#' + $(this).data('scroll-to')).length) {
			let offset = $(this).data('scroll-to-offset') || 0;
			scroll_to('#' + $(this).data('scroll-to'), offset, 'slow');
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
	});

	/* The scroll monitor */
	var myElement = $('body');
	var scrollOffset = -110;
	if ($(window).width() <= 780) {
		scrollOffset = -60;
	}
	var elementWatcher = scrollMonitor.create(myElement, scrollOffset);
	elementWatcher.stateChange(function() {
		$('header').toggleClass('collapsed', this.isAboveViewport)
	});

	/* The grecaptcha thing */
	$('#button-submit').on('click', function(e)
	{
		e.preventDefault();
		if ('' != $('#name').val() && 
				/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test($('#email').val()) &&
				'' != $('#telephone').val() &&
				'' != $('#comments').val()
				){
			grecaptcha.execute({action: 'homepage'});
		} else {
			$('.notification').html('Ocurrio un error al enviar tu mensaje, por favor verifica que hayas llenado todos los campos correctamente.').addClass('warning').removeClass('hidden');
			notification_display();
		}
	});

	/* The content toggle links */
	if (0 < $('.team-bio').length) {
		toggle_content();
	}

	/* Scroll to full post */
	if (0 < $('#full-post').length) {
		scroll_to('#full-post', -360, 'slow');
	}

});
